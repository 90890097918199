import * as MixpanelAuthActions from '@analytics/mixpanel/actions/Auth';
import * as CelebritiesAPI from '@api/Celebrities';
import Routes from '@constants/routes';
import LoginForm from '@features/auth/LoginForm/LoginForm';
import { useYela } from '@hooks/useYela';
import BaseLayout from '@layouts/Base/BaseLayout';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { BackgroundContainer, LoginFormWrapper } from './Login.styles';
import { LoginProps } from './Login.types';

const Login = ({ queryParams }: LoginProps): JSX.Element => {
  const router = useRouter();
  const { language } = useYela();
  const [celebrityInfo, setCelebrityInfo] = useState<CelebritiesAPI.CelebrityResponse>({} as CelebritiesAPI.CelebrityResponse);
  const [initializationDone, setInitializationDone] = useState<boolean>(false);

  const Background =
    (queryParams.creator && celebrityInfo.photo) || !queryParams.creator ? (
      <BackgroundContainer
        image={
          celebrityInfo.photo
            ? celebrityInfo.photo
            : language == 'en'
            ? 'https://ik.imagekit.io/yela/Production/Marquee/latest_marquee_-_ENG_sMfJYwJCd.png'
            : 'https://ik.imagekit.io/yela/Production/Marquee/latest_marquee_-_AR_vVpFai6UM.png'
        }
      />
    ) : undefined;
  const creator = queryParams.creator || '';
  const action = queryParams.action || '';
  const type = queryParams.type || '';

  useEffect(() => {
    MixpanelAuthActions.onLoginPageLoaded({ router });
  }, []);

  useEffect(() => {
    const getCelebrityInfo = async () => {
      if (creator == '') return setInitializationDone(true);
      const accessToken = localStorage.getItem('fb_token');
      if ('booking' !== action || !['video', 'audio'].includes(type)) {
        if (accessToken) localStorage.removeItem('fb_token');
        return router.push(Routes.LOGIN);
      }
      const response = await CelebritiesAPI.GetCelebrityInfo({ slug: creator });
      if (!response.success && response.code == 'not-found') {
        if (accessToken) localStorage.removeItem('fb_token');
        return router.push(Routes.LOGIN);
      }
      const celebInfo = response.success ? (response.data as CelebritiesAPI.CelebrityResponse) : ({} as CelebritiesAPI.CelebrityResponse);
      if (!celebInfo.available) {
        if (accessToken) localStorage.removeItem('fb_token');
        await router.push(Routes.LOGIN);
        return;
      }
      setCelebrityInfo(celebInfo);
      setInitializationDone(true);
    };

    getCelebrityInfo();
  }, [router]);

  return (
    <BaseLayout showFooter={false} background={Background}>
      <LoginFormWrapper>
        <LoginForm loginWithCelebrityInfo={{ celebrityInfo, creator, action, type, initializationDone }} />
      </LoginFormWrapper>
    </BaseLayout>
  );
};

export default Login;
