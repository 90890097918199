export const I18N_LOGIN_CONTEXT = 'login';
export const FORM_TITLE = 'form-title';
export const FORM_MESSAGE_PREFIX = 'form-message-prefix';
export const FORM_MESSAGE_LINK = 'form-message-link';
export const FORM_LOG_IN_WITH_EMAIL = 'form-log-in-with-email';
export const FORM_EMAIL_LABEL = 'form-email-label';
export const FORM_EMAIL_PLACEHOLDER = 'form-email-placeholder';
export const FORM_INVALID_EMAIL_MESSAGE = 'form-email-invalid';
export const FORM_PASSWORD_LABEL = 'form-password-label';
export const FORM_PASSWORD_PLACEHOLDER = 'form-password-placeholder';
export const FORM_INVALID_PASSWORD_MESSAGE = 'form-password-invalid';
export const FORM_FORGOT_PASSWORD_LINK = 'form-forgot-password-link';
export const FORM_SUBMIT_BUTTON_TEXT = 'form-submit-button';
