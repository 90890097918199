import Button from '@components/Button/Button';
import FormError from '@components/Form/Error/FormError';
import { Heading5, Subtitle3 } from '@components/Texts/Texts';
import tw, { styled } from 'twin.macro';

export const LoginFormTitle = styled(Heading5)`
  ${tw`text-light-high`};
`;

export const MessageContainer = styled.div<{ centerOnSmall: boolean }>`
  ${props => (props.centerOnSmall ? tw`sm:mt-4 sm:mb-2 sm:text-center` : tw`sm:my-4 sm:text-start`)};
  ${props => (props.centerOnSmall ? tw`md:mt-4 md:mb-8 md:text-center` : tw`md:my-4 md:text-start`)};
  ${tw`lg:my-4`};
  ${tw`xl:my-4`};
`;

export const LoginFormError = styled(FormError)`
  ${tw`mb-2`}
`;

export const InnerWrapper = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  ${tw`py-4 relative mt-4`};
`;

export const LogInWithEmailTextContainer = styled.div`
  ${tw`absolute top-0 flex flex-row justify-center w-full`};
`;

export const LogInWithEmailText = styled(Subtitle3)`
  transform: translateY(-9px);
  ${tw`text-light-medium px-1`};
`;

export const FormElementsWrapper = styled.div`
  ${tw`grid auto-rows-auto gap-1`}
`;

export const LoginFormButton = styled(Button)`
  ${tw`mt-4`}
`;
