import * as MixpanelAuthActions from '@analytics/mixpanel/actions/Auth';
import * as AuthAPI from '@api/Auth';
import { setUserData } from '@common/utils';
import FacebookConnect from '@components/FacebookConnect/FacebookConnect';
import FormElement from '@components/Form/Element/FormElement';
import { useYelaForm } from '@components/Form/Form';
import FormInput from '@components/Form/Input/FormInput';
import TextWithLink from '@components/TextWithLink/TextWithLink';
import Routes from '@constants/routes';
import {
  FORM_EMAIL_LABEL,
  FORM_EMAIL_PLACEHOLDER,
  FORM_FORGOT_PASSWORD_LINK,
  FORM_INVALID_EMAIL_MESSAGE,
  FORM_INVALID_PASSWORD_MESSAGE,
  FORM_LOG_IN_WITH_EMAIL,
  FORM_MESSAGE_LINK,
  FORM_MESSAGE_PREFIX,
  FORM_PASSWORD_LABEL,
  FORM_PASSWORD_PLACEHOLDER,
  FORM_SUBMIT_BUTTON_TEXT,
  FORM_TITLE,
  I18N_LOGIN_CONTEXT,
} from '@features/auth/LoginForm/LoginForm.constants';
import {
  FormElementsWrapper,
  InnerWrapper,
  LoginFormButton,
  LoginFormError,
  LoginFormTitle,
  LogInWithEmailText,
  LogInWithEmailTextContainer,
  MessageContainer,
} from '@features/auth/LoginForm/LoginForm.styles';
import * as Sentry from '@sentry/nextjs';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { LoginFormProps, LoginInputs, TLoginInputs } from './LoginForm.types';

const LoginForm = (props: LoginFormProps): JSX.Element => {
  const initializationDone = props.loginWithCelebrityInfo.initializationDone;
  const creator = props.loginWithCelebrityInfo.creator;
  const action = props.loginWithCelebrityInfo.action;
  const type = props.loginWithCelebrityInfo.type;
  const queryString = creator != '' ? '?creator=' + creator + '&action=' + action + '&type=' + type : '';

  const {
    trigger,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useYelaForm<TLoginInputs>(LoginInputs);
  const router = useRouter();
  const { t } = useTranslation(I18N_LOGIN_CONTEXT);
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const processLogIn: SubmitHandler<TLoginInputs> = async data => {
    if (buttonIsLoading) return;

    await trigger();
    if (errors === undefined || errors == {}) return;

    setButtonIsLoading(true);
    const responseData = await AuthAPI.LogIn(data);

    if (responseData.success && responseData.data?.accessToken) {
      MixpanelAuthActions.onLogin({ router, data: responseData.data, authenticationMethod: 'Email' });
      setUserData(responseData.data as AuthAPI.LogInResponse);
      if (creator == '') {
        router.push(Routes.HOME);
      } else if (action == 'booking') {
        router.push(Routes.BOOKING(creator, type));
      }
    } else {
      setButtonIsLoading(false);
      setErrorMessage(responseData.code);
      MixpanelAuthActions.onLoginFailed({ router, code: responseData.code, email: data.email });
    }
  };

  const onFacebookLogin = async (accessToken: string, email?: string) => {
    if (buttonIsLoading) return;
    setButtonIsLoading(true);

    const responseData = await AuthAPI.FacebookLogIn({ accessToken: accessToken });
    if (responseData.success && responseData.data?.accessToken) {
      responseData.data?.type == 'login'
        ? MixpanelAuthActions.onLogin({ router, data: responseData.data, authenticationMethod: 'Facebook' })
        : MixpanelAuthActions.onRegister({
            router,
            data: responseData.data,
            email: responseData.data?.email,
            authenticationMethod: 'Facebook',
          });

      setUserData(responseData.data as AuthAPI.LogInResponse);
      if (creator == '') {
        router.push(Routes.HOME);
      } else if (action == 'booking') {
        router.push(Routes.BOOKING(creator, type));
      }
    } else {
      setButtonIsLoading(false);
      setErrorMessage(responseData.code);
      MixpanelAuthActions.onFacebookAccessFailed({ router, code: responseData.code });
      if (responseData.code == 'fb-already-have-connection') {
        Sentry.captureMessage('Facebook Already Has Connection (Error)', {
          extra: {
            _response_email: email,
          },
        });
      }
    }
  };

  const onFacebookButtonClicked = async () => {
    MixpanelAuthActions.onFacebookAccessButtonClicked({
      router,
    });
  };

  return (
    <>
      <LoginFormTitle>{t(FORM_TITLE)}</LoginFormTitle>
      <MessageContainer centerOnSmall={false}>
        <TextWithLink
          type='medium'
          prefix={t(FORM_MESSAGE_PREFIX)}
          link={t(FORM_MESSAGE_LINK)}
          suffix={''}
          onClick={() => {
            MixpanelAuthActions.onRegistrationLinkClicked({
              router,
            });
            router.push(Routes.REGISTER + queryString);
          }}
          href={Routes.REGISTER + queryString}
        />
      </MessageContainer>
      {initializationDone && (
        <FacebookConnect
          onClick={onFacebookButtonClicked}
          onLogin={onFacebookLogin}
          dynamicURIParams={{ creator, action, type }}
          callerPage='login'
          disabled={buttonIsLoading}
        />
      )}
      <InnerWrapper>
        {errorMessage && <LoginFormError text={errorMessage} />}
        <LogInWithEmailTextContainer>
          <LogInWithEmailText>{t(FORM_LOG_IN_WITH_EMAIL)}</LogInWithEmailText>
        </LogInWithEmailTextContainer>
        <form onSubmit={handleSubmit(processLogIn)} noValidate={true}>
          <FormElementsWrapper>
            <FormElement hasError={!!errors.email} labelText={t(FORM_EMAIL_LABEL)} errorText={t(FORM_INVALID_EMAIL_MESSAGE)}>
              <FormInput name='email' type='email' register={register} placeholder={t(FORM_EMAIL_PLACEHOLDER)} hasError={!!errors.email} />
            </FormElement>

            <FormElement hasError={!!errors.password} labelText={t(FORM_PASSWORD_LABEL)} errorText={t(FORM_INVALID_PASSWORD_MESSAGE)}>
              <FormInput
                name='password'
                type='password'
                register={register}
                placeholder={t(FORM_PASSWORD_PLACEHOLDER)}
                hasError={!!errors.password}
              />
            </FormElement>
            <TextWithLink
              type='medium'
              prefix={''}
              link={t(FORM_FORGOT_PASSWORD_LINK)}
              suffix={''}
              onClick={() => {
                MixpanelAuthActions.onForgotPasswordLinkClicked({
                  router,
                });
                router.push(Routes.FORGOT_PASSWORD);
              }}
              href={Routes.FORGOT_PASSWORD}
            />
            {initializationDone && (
              <LoginFormButton
                uppercase
                type='submit'
                loadingState={buttonIsLoading}
                text={t(FORM_SUBMIT_BUTTON_TEXT)}
                onClick={() => {
                  MixpanelAuthActions.onLoginButtonClicked({
                    router,
                    email: getValues('email'),
                  });
                }}
              />
            )}
          </FormElementsWrapper>
        </form>
      </InnerWrapper>
    </>
  );
};

export default LoginForm;
