import { getImageSrc } from '@common/image-helper';
import tw, { styled, theme } from 'twin.macro';

export const LoginFormWrapper = styled.div`
  ${tw`sm:col-start-1 sm:col-end-5 sm:mt-7 sm:mb-2`};
  ${tw`md:col-start-2 md:col-end-8 md:my-8`};
  ${tw`lg:col-span-4 lg:pb-2 lg:mt-6`};
  ${tw`xl:col-span-4 xl:pb-2 xl:mt-6`};
`;

export const BackgroundContainer = styled.div<{ image: string }>`
  ${tw`sm:hidden`};
  ${tw`md:hidden`};
  max-width: 1920px;
  max-height: 728px;
  z-index: -1;
  background-repeat: no-repeat;
  ${tw`fixed w-full left-0 right-0 top-0 bottom-0 mx-auto`};
  [dir='rtl'] & {
    transform: scaleX(-1);
  }

  @media (min-width: ${theme`screens.lg.min`}) {
    background-size: cover;
    background-image: linear-gradient(180deg, rgba(7, 7, 8, 0) 65.59%, #070708 100%),
      linear-gradient(180deg, rgba(7, 7, 8, 0.35) 0%, rgba(7, 7, 8, 0) 13.19%),
      radial-gradient(100% 391.26% at 100% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%),
      url(${props => getImageSrc({ imageSrc: props.image, width: 1920 })});
    background-position-x: 75%;
  }
`;
