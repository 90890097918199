import * as CelebritiesAPI from '@api/Celebrities';
import Joi from 'joi';

export type TLoginInputs = {
  email: string;
  password: string;
};

export const LoginInputs = Joi.object<TLoginInputs>({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().min(6).required(),
});

export interface LoginFormProps {
  loginWithCelebrityInfo: {
    celebrityInfo: CelebritiesAPI.CelebrityResponse;
    creator: string;
    action: string;
    type: string;
    initializationDone: boolean;
  };
}
